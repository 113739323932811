import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Button from './UI/Button'
import Menu from './UI/Menu'
import Title from './UI/Title'
import I18n from './UI/I18n'
import Info from './UI/Info'
import Photo from './UI/Photo'
import Carrousel from './UI/Carrousel'
import Footer from './Footer'
import ContactForm from './ContactForm'
import { contactDealer } from '../api'


function Partnership({ data }) {
  const [showHelp, setShowHelp] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const { id, company, phone, logo, instagram, facebook, youtube } = data.strapi.dealer

  return ( 
    <>
      <Menu showHelp={showHelp} setShowHelp={setShowHelp} instagram={instagram} facebook={facebook} youtube={youtube}>
        <div className="leading-tight lg:text-right">Réserver votre borne photo avec {company} - {phone}</div>
        <Button className="mt-2 lg:mt-0 lg:ml-4" variant="white" onClick={() => setShowForm(true)}>Nous contacter</Button>
      </Menu>
      {showForm &&
        <ContactForm company={company} phone={phone} close={() => setShowForm(false)} send={data => contactDealer({ id }, data)} />
      }
      <div className="flex flex-col lg:flex-row w-full justify-center items-center mt-10 lg:mt-32 mb-10">
        <img src="/logo.svg" className="h-16 lg:h-24 mx-2 lg:mx-4" />
        {logo &&
          <>
            <div className="font-thin text-2xl lg:text-4xl mx-2 lg:mx-4 text-pink">x</div>
            <img src={logo.url} className="h-16 lg:h-32 mx-2 lg:mx-4" />
          </>
        }
      </div>
      <div className="mt-4 lg:mt-10">
        <div className="max-w-screen-lg mx-auto px-4 flex justify-center flex-col lg:flex-row items-start lg:mb-8">
          <div className="lg:mr-16 flex-shrink-0 w-full lg:w-1/2 px-12 lg:p-4">
            <Photo index={0} />
          </div>
          <div className="mt-16 mx-auto">
            <Title className="lg:text-left">
              Smiley Box x {company}
            </Title>
            <div className="leading-relaxed text-base lg:text-lg my-5 lg:my-12 text-gray-600">
              <li>Montage simple en 5min</li>
              <li>600 tirages inclus</li>
              <li>Contour photo personnalisable</li>
              <li>Galerie de vos photos en ligne</li>
              <li>Garantie satisfaction à 100%</li>
            </div>
            <Button className="mt-10 mx-auto lg:mx-0" onClick={() => setShowForm(true)}>Nous contacter</Button>
          </div>
        </div>
        <div className="w-screen bg-pink p-10 my-4 text-white shadow">
          <div className="flex flex-col lg:flex-row justify-center items-center max-w-screen-lg mx-auto">
            <div className="w-full lg:w-1/2 flex-shrink-0 leading-tight lg:leading-snug text-base lg:text-lg">
              <Title className="mb-8" white>Réservez l’esprit léger <div className="inline font-thin"><br />votre photobooth avec {company}</div></Title>
              <p className="my-3">Concept original, ludique, ultra facile d’utilisation… <strong>C’est la borne photo à louer pour tous vos événements !</strong></p>
              <p className="my-3">Vos invités imprimeront en quelques secondes leurs photos de qualité professionnelle et <strong>repartiront avec des souvenirs qui donneront le smile !</strong></p>
              <p className="my-3">{company} est formé par Smiley Box dans la location de photobooth d’événement, nul besoin de s'occuper de la logistique, tout est pris en charge. </p>
              <p className="my-3"><strong>Alors, n’attendez plus pour réserver !</strong></p>
              <Button className="my-8 mx-auto lg:mx-0" onClick={() => setShowForm(true)}>Nous contacter</Button>
            </div>
            <div>
              <Photo index={1} className="mt-4 -mb-16 lg:m-8" />
            </div>
          </div>
        </div>
        <div className="w-full pt-5 pb-10">
          <Title className="text-center">Notre offre comprend</Title>
          <Carrousel className="items-stretch">
            {[
              { description: '<strong>Imprimante à sublimation thermique</strong> facile à utiliser pour des impressions de qualité labo', image: '/icons/printer.png' },
              { description: 'Photobooth avec <strong>appareil photo reflex numérique</strong> ', image: '/icons/camera.png' },
              { description: '<strong>600 impressions</strong>  photos comprises au format 7,5x10 cm', image: '/icons/photos.png' },
              { description: 'Profitez de <strong>5 jours de location</strong de votre borne photo', image: '/icons/calendar.png' },
              // { description: '<strong>Flash professionnel</strong> pour un éclairage optimal', image: '/icons/flash.png' },
              { description: '<strong>Personnalisez le cadre</strong> de votre photo imprimée', image: '/icons/brush.png' },
              { description: 'Photos mises à disposition dans une <strong>galerie en ligne</strong>', image: '/icons/computer.png' },
              { description: 'Photobooth et logiciel entièrement <strong>créés et développés par nos soins</strong>', image: '/icons/france.png' },
            ].map(i => (
              <div key={i.title} className="flex flex-col justify-center select-none items-center w-40 lg:w-56 m-2 lg:m-4 text-center bg-white px-2 py-4 lg:px-4 rounded shadow leading-tight lg:leading-normal">
                <div className="relative h-20 w-20 mb-2 mt-1">
                  <img src={i.image} className="relative flex justify-center items-center select-none h-20 z-10 pointer-events-none" />
                  <div className="bg-pink w-12 h-12 absolute bottom-0 left-0 -ml-6 z-0" />
                </div>
                <p className="text-sm lg:text-base leading-tight mt-2 text-gray-600" dangerouslySetInnerHTML={{ __html: i.description }} />
              </div>
            ))}
          </Carrousel>
        </div>
        <div className="relative w-screen mb-12 pt-6 text-white">
          <div className="absolute top-0 bg-pink w-full h-full max-h-3/4 lg:max-h-4/5 shadow" />
          <Title className="text-center" white>Et en vrai, elle ressemble à quoi ?</Title>
          <Carrousel className="">
            <Photo index={2} className="h-56 m-2 lg:m-4" />
            <Photo index={3} className="h-56 m-2 lg:m-4" />
            <Photo index={4} className="h-56 m-2 lg:m-4" />
            <Photo index={5} className="h-56 m-2 lg:m-4" />
            <Photo index={6} className="h-56 m-2 lg:m-4" />
            <Photo index={7} className="h-56 m-2 lg:m-4" />
            <Photo index={8} className="h-56 m-2 lg:m-4" />
          </Carrousel>
        </div>
        <Button className="my-4 lg:my-12 mx-auto" onClick={() => setShowForm(true)}>Nous contacter</Button>
      </div>
      <Footer showHelp={() => setShowHelp(true)} />
    </>  
  )
}

export const query = graphql`
  query PartnerQuery($id: ID!) {
    strapi {
      dealer(id: $id) {
        id
        company
        phone
        logo {
          url
          formats
          ext
        }
        instagram
        facebook
        youtube
      }
    }
  }
`
export default Partnership
